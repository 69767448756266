import {createGlobalStyle} from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root {
    --ikon-bg-color: rgba(255, 255, 255, 1);
    --ikon-bg-off-color: rgba(255, 255, 255, 0);
    --ikon-focus-color: #00f;
    --ikon-hl-color: #ff0;
    --ikon-error-color: #c00;
  }
  html, body {
    padding:0;
    margin: 0;
    -webkit-overflow-scrolling: touch; 
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    background-color: var(--ikon-bg-color);
  }

  ::selection {
    background: var(--ikon-hl-color, #ff0);
  }

  sup {
    transform: translateY(2px);
  }

  @media (any-pointer: fine) {
    & a {
      transition: color 0.3s;
      &:hover {
        color: #555;
      }
    }
  }

  ul,ol {
    padding: 0 0 0 1.5em;
    margin: 0 0 1em 0;
    li {
      margin: 0 0 0.3em 0;
      padding: 0;
    }
  }

  ul li {
    list-style-type: '● ';
    &::marker {
      font-size:  0.8em;
    }
  }

  ::placeholder,
  input::placeholder  {
    font-family: "ABC Diatype", Arial, Helvetica, sans-serif;
    line-height: inherit !important;
    font-size: inherit !important;
    font-style: inherit !important;
    letter-spacing: inherit !important;
    color: #777 !important;
  }

  ${(props: any) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
      body {
        ${props.theme.textStyle(breakpoint, "body")};
      }

      
      p {
        max-width: ${props.theme.bodyCopyMaxWidth?.[breakpoint] ?? 600}px;
        margin: ${props.theme.spaceFontTop(breakpoint, "body")} 0 ${props.theme.spaceFontBottom(breakpoint, 8, "body")} 0;
      }

      h1, .h1 {
        ${props.theme.textStyle(breakpoint, "h1Heading")};
        margin: ${props.theme.spaceFontTop(breakpoint, "h1Heading")} 0 ${props.theme.spaceFontBottom(breakpoint, 8, "h1Heading")} 0;
      }

      h2, .h2 {
        ${props.theme.textStyle(breakpoint, "h2")};
        margin: ${props.theme.spaceFontTop(breakpoint, "h2")} 0 ${props.theme.spaceFontBottom(breakpoint, 7, "h2")} 0;
      }

      h3, .h3 {
        ${props.theme.textStyle(breakpoint, "h3")};
        margin: ${props.theme.spaceFontTop(breakpoint, "h3")} 0 ${props.theme.spaceFontBottom(breakpoint, 7, "h3")} 0;
      }

      h4, .h4 {
        ${props.theme.textStyle(breakpoint, "h4")};
        margin: ${props.theme.spaceFontTop(breakpoint, "h4")} 0 ${props.theme.spaceFontBottom(breakpoint, 7, "h4")} 0;
      }

      sup {
        display: inline-block;
        padding-left: 0.2em;
        ${props.theme.textStyle(breakpoint, "caption")};
      }
    `;
    })}
`;
