import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef
} from "react";

type CssVarContextVars = {
  sbw: number;
  lbh: number;
  h100p: number;
  h100vh: number;
};

export type CssVarContextType = {
  getVars: () => CssVarContextVars;
};

const cssVarContextVarsDefault: CssVarContextVars = {
  sbw: 0,
  lbh: 0,
  h100p: 0,
  h100vh: 0,
};

const cssVarContextDefault: CssVarContextType = {
  getVars: () => cssVarContextVarsDefault,
};

// create context
const CssVarsContext = createContext<CssVarContextType>(cssVarContextDefault);

export const useCssVarsContext = () => useContext(CssVarsContext);

// context provider
export const CssVarsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const cssVarsRef = useRef<CssVarContextVars>(cssVarContextVarsDefault);
  const currentOrientationRef = useRef("");

  const getVars = useCallback(() => cssVarsRef.current, []);

  useEffect(() => {
    if (typeof window === "undefined") return;

    const onChange = () => {
      const orientation = window.innerWidth > window.innerHeight ? "landscape" : "portrait";

      if (orientation !== currentOrientationRef.current) {
        currentOrientationRef.current = orientation;
        const outer = document.createElement("div");

        try {
          document.body.appendChild(outer);

          outer.style.visibility = "hidden";
          outer.style.width = "100px";
          outer.style.height = "100vh";
          outer.style.overflow = "scroll";
          const widthNoScroll = outer.offsetWidth;

          const inner = document.createElement("div");
          inner.style.width = "100%";
          outer.appendChild(inner);
          const widthScroll = inner.offsetWidth;

          const h100vh = Math.max(window.innerHeight, outer.offsetHeight);

          const sbw = widthNoScroll - widthScroll;

          outer.style.height = "100%";

          const h100p = Math.max(window.innerHeight, outer.offsetHeight)
          const lbh = h100vh - h100p;

          document.documentElement.style.setProperty("--sbw", `${sbw.toFixed(0)}px`);
          document.documentElement.style.setProperty("--lbh", `${lbh.toFixed(0)}px`);
          document.body.removeChild(outer);

          cssVarsRef.current = {
            sbw,
            lbh,
            h100vh,
            h100p,
          };
          
        } catch (err) {
          cssVarsRef.current = {
            sbw: 0,
            lbh: 0,
            h100vh: window.innerHeight,
            h100p: window.innerHeight,
          };
        }
      }
      
    }
    onChange();

    window.addEventListener("resize", onChange);

    return () => {
      window.removeEventListener("resize", onChange);
    };
    
  }, []);

  return (
    <CssVarsContext.Provider
      value={{
        getVars,
      }}
    >
      {children}
    </CssVarsContext.Provider>
  );
};
