import { appConfig } from "~/config";

import { breakpointEMs } from "./breakpoints";
export * from "./breakpoints";

/*

ABC Diatype

https://iamvdo.me/en/blog/css-font-metrics-line-height-and-vertical-align

Em-Square: 1000
Cap Height: Configured 675 real 700 - 736
Desc: Configured -319 real ~ - 222

Used Cap + Desc = ~ 1250  >>> 100px approx 125px content height at line-height normal

*/

export const themeSpace = (
  breakpoint: string,
  level: number = 1,
  adjust: number = 0
) => {
  let b = breakpoint.replace("Landscape", "");

  if (["base", "mobile"].includes(b) && level >= 9) return 9;

  let space = appConfig.goldenRatioBase?.[b] ?? 700;
  const gC = 1.61803398875;

  for (let i = 0; i < level; i++) {
    space = space / gC;
  }
  return space - adjust;
};

export const themeSpacePx = (
  breakpoint: string,
  level: number = 1,
  adjust?: string
) => {
  const px = `${Math.round(themeSpace(breakpoint, level))}px`;

  return adjust ? `calc(${px} ${adjust})` : px;
};

const ABCDIATYPE_ASCENDER = 0.905; //0.932;
const ABCDIATYPE_DESCENDER = 0.319;
const ABCDIATYPE_CAPHEIGHT = 0.7;
const ABCDIATYPE_ADDITIONALGAPHEIGHT = 2;

export const themeFontSize = (capHeight: number) =>
  capHeight / ABCDIATYPE_CAPHEIGHT;

export const themeFontSizePx = (capHeight: number) => {
  return `${themeFontSize(capHeight).toFixed(4)}px`;
};

export const themeFontDistanceTop = (capHeight: number, gapHeight: number) => {
  const distanceTop = ABCDIATYPE_ASCENDER - ABCDIATYPE_CAPHEIGHT;
  // ABCDIATYPE_DESCENDER = distance to bottom
  
  const fontSize = themeFontSize(capHeight);
  const valign = (distanceTop - ABCDIATYPE_DESCENDER) * fontSize;

  return (
    gapHeight +
    ABCDIATYPE_ADDITIONALGAPHEIGHT -
    (gapHeight + ABCDIATYPE_ADDITIONALGAPHEIGHT - valign) / 2
  );
};

export const themeFontDistanceTopPx = (
  capHeight: number,
  gapHeight: number
) => {
  return `${themeFontDistanceTop(capHeight, gapHeight).toFixed(2)}px`;
};

export const themeFontDistanceBottom = (
  capHeight: number,
  gapHeight: number
) => {
  const distanceTop = ABCDIATYPE_ASCENDER - ABCDIATYPE_CAPHEIGHT;
  const distanceBottom = ABCDIATYPE_DESCENDER;
  const fontSize = themeFontSize(capHeight);
  const valign = (distanceTop - distanceBottom) * fontSize;

  return (gapHeight + ABCDIATYPE_ADDITIONALGAPHEIGHT - valign) / 2;
};

export const themeFontDistanceBottomPx = (
  capHeight: number,
  gapHeight: number
) => {
  return `${themeFontDistanceBottom(capHeight, gapHeight).toFixed(4)}px`;
};

export const themeFontValign = (capHeight: number) => {
  const distanceTop = ABCDIATYPE_ASCENDER - ABCDIATYPE_CAPHEIGHT;
  const distanceBottom = ABCDIATYPE_DESCENDER;
  const fontSize = themeFontSize(capHeight);

  return (distanceTop - distanceBottom) * fontSize * -1;
};

export const themeFontValignPx = (capHeight: number) => {
  return `${themeFontValign(capHeight).toFixed(4)}px`;
};

export const themeLineHeight = (capHeight: number, gapHeight: number) => {
  const distanceTop = ABCDIATYPE_ASCENDER - ABCDIATYPE_CAPHEIGHT;
  const distanceBottom = ABCDIATYPE_DESCENDER;
  const fontSize = themeFontSize(capHeight);
  const valign = (distanceTop - distanceBottom) * fontSize;

  return (
    capHeight + valign + (gapHeight + ABCDIATYPE_ADDITIONALGAPHEIGHT - valign)
  );
};

export const themeLineHeightPx = (capHeight: number, gapHeight: number) => {
  return `${themeLineHeight(capHeight, gapHeight).toFixed(4)}px`;
};

export const themeImgSizes = (
  breakpoints: Record<
    string,
    | string
    | {
        landscape: string;
        portrait: string;
      }
  >
) => {
  return {
    landscape: Object.keys(breakpoints)
      .map((b: string) => {
        return `${
          b !== "base" ? `(min-width: ${(breakpointEMs as any)?.[b]}em) ` : ``
        }${
          typeof breakpoints[b] === "string"
            ? breakpoints[b]
            : (breakpoints[b] as any)?.landscape ?? "100vw"
        }`;
      })
      .join(","),
    portrait: Object.keys(breakpoints)
      .map((b: string) => {
        return `${
          b !== "base" ? `(min-width: ${(breakpointEMs as any)?.[b]}em) ` : ``
        }${
          typeof breakpoints[b] === "string"
            ? breakpoints[b]
            : (breakpoints[b] as any)?.portrait ?? "100vw"
        }`;
      })
      .join(","),
  };
};

const createFontStyle = (
  breakpoint: string,
  size: number,
  space: number,
  fontWeight?: number,
  letterSpacing?: string | null,
  textTransform?: string,
  topAdjustment?: number
) => {
  return {
    fontSize: themeFontSizePx(themeSpace(breakpoint, size)),
    lineHeight: themeLineHeightPx(
      themeSpace(breakpoint, size),
      themeSpace(breakpoint, space)
    ),

    spaceTop: `-${(
      themeFontDistanceTop(
        themeSpace(breakpoint, size),
        themeSpace(breakpoint, space)
      ) * (topAdjustment ?? 1)
    ).toFixed(2)}px`,
    spaceBottom: `- ${themeFontDistanceBottomPx(
      themeSpace(breakpoint, size),
      themeSpace(breakpoint, space)
    )}`,
    valign: themeFontValign(themeSpace(breakpoint, size)),

    ...(fontWeight ? { fontWeight } : { fontWeight: 200 }),
    ...(textTransform ? { textTransform } : {}),
    ...(letterSpacing ? { letterSpacing } : {}),
  };
};

export const theme = {
  colors: {
    primary: "yellow",
    secondary: "red",
  },
  zIndex: {
    menu: 10,
  },
  goldenRatio: appConfig.goldenRatioBase,
  breakpoints: {
    base: `@media screen and (min-width: 1em)`,
    mobile: `@media screen and (min-width: ${breakpointEMs.mobile}em)`, // ~360px
    mobileLandscape: `@media screen and (min-width: ${breakpointEMs.mobileLandscape}em)`, // ~448px
    tablet: `@media screen and (min-width: ${breakpointEMs.tablet}em)`, // ~720px
    tabletLandscape: `@media screen and (min-width: ${breakpointEMs.tabletLandscape}em)`, // ~992px
    desktop: `@media screen and (min-width: ${breakpointEMs.desktop}em)`, // 1200px
    screen: `@media screen and (min-width: ${breakpointEMs.screen}em)`, // ~1760px
  },
  pageMargin: {
    // spacing yellow/7
    base: 10,
    mobile: 17,
    mobileLandscape: 17,
    tablet: 34,
    tabletLandscape: 34,
    desktop: 60,
    screen: 90,
  },
  gutterWidth: {
    // spacing orange/8
    base: 12,
    mobile: 12,
    mobileLandscape: 12,
    tablet: 21,
    tabletLandscape: 21,
    desktop: 21,
    screen: 21,
  },

  bodyCopyMaxWidth: {
    base: 450,
    mobile: 450,
    mobileLandscape: 450,
    tablet: 600,
    tabletLandscape: 600,
    desktop: 750,
    screen: 750,
  },

  space: function (breakpoint: string, level: number = 1, adjust: number = 0) {
    return themeSpace(breakpoint, level, adjust);
  },

  spacePx: function (breakpoint: string, level: number = 1, adjust?: string) {
    return themeSpacePx(breakpoint, level, adjust);
  },

  spaceFontTop: function (breakpoint: string, style: string) {
    return `${
      (this as any)?.typography?.[breakpoint.replace("Landscape", "")]?.[style]
        ?.spaceTop ?? ""
    }`;
  },

  spaceFontBottom: function (breakpoint: string, level: number, style: string) {
    return `calc(${(this as any).spacePx(
      breakpoint.replace("Landscape", ""),
      level
    )} ${
      (this as any)?.typography?.[breakpoint.replace("Landscape", "")]?.[style]
        ?.spaceBottom ?? ""
    })`;
  },

  margin: function (breakpoint: string) {
    return (this as any).pageMargin?.[breakpoint] ?? 0;
  },

  marginPx: function (breakpoint: string) {
    return `${((this as any)?.pageMargin?.[breakpoint] ?? 0).toFixed(3)}px`;
  },

  fontValign: function (breakpoint: string, style: string) {
    return (
      (this as any)?.typography?.[breakpoint.replace("Landscape", "")]?.[style]
        ?.valign ?? 0
    );
  },

  fontValignPx: function (breakpoint: string, style: string) {
    return `${(
      (this as any)?.typography?.[breakpoint.replace("Landscape", "")]?.[style]
        ?.valign ?? 0
    ).toFixed(4)}px`;
  },

  gutter: function (breakpoint: string) {
    return (this as any).gutterWidth?.[breakpoint] ?? 0;
  },

  gutterPx: function (breakpoint: string) {
    return `${((this as any)?.gutterWidth?.[breakpoint] ?? 0).toFixed(3)}px`;
  },

  apply: function (
    breakpoints: string[] | string,
    callback: Function,
    addBaseMediaQuery: boolean = false
  ) {
    let keys = Array.isArray(breakpoints) ? breakpoints : [];

    if (breakpoints === "default")
      keys = ["base", "mobile", "tablet", "desktop", "screen"];

    if (breakpoints === "all")
      keys = [
        "base",
        "mobile",
        "mobileLandscape",
        "tablet",
        "tabletLandscape",
        "desktop",
        "screen",
      ];

    return keys
      .reduce((carry: string[], breakpoint: string) => {
        const payload = callback.call(null, breakpoint);
        carry.push(
          // add css media query if needed
          addBaseMediaQuery || breakpoint !== "base"
            ? `${(this as any).breakpoints[breakpoint]} {
              ${payload};
            }`
            : payload
        );
        return carry;
      }, [])
      .join("\n");
  },
  typography: {
    base: {
      h0Heading: createFontStyle("base", 6, 9, 200, "-0.06em", "uppercase"),
      h1Heading: createFontStyle("base", 6, 9, 200, "-0.06em", "uppercase"),
      h1: createFontStyle("base", 6, 9, 200, "-0.06em"),
      h2Heading: createFontStyle("base", 7, 9, 200, "-0.06em", "uppercase", 0.8),
      h2HeadingBold: createFontStyle("base", 7, 9, 700, "-0.06em", "uppercase"),
      h2: createFontStyle("base", 7, 9, 200, "-0.06em"),

      h3Heading: {
        ...createFontStyle("base", 9, 9, 700, "-0.025em", "uppercase", 0.3),
        fontSize: "14px",
        lineHeight: "1.3em",
      },

      h3: {
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "1.3em",
        spaceTop: "- 0.1em",
        spaceBottom: "- 0.2em",
        letterSpacing: "-0.025em",
        valign: themeFontValign(themeSpace("base", 8)),
      },

      h4Heading: {
        ...createFontStyle("base", 9, 9, 700, null, "uppercase"),
        fontSize: "14px",
        lineHeight: "1.3em",
      },

      h4: {
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "1.3em",
        spaceTop: "- 0.1em",
        spaceBottom: "- 0.2em",
        valign: themeFontValign(themeSpace("base", 9)),
      },

      body: {
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "1.3em",
        spaceTop: "- 1px",
        spaceBottom: "- 2px",
      },

      captionTitle: {
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "1.3em",
        spaceTop: "0px",
        spaceBottom: "- 1px",
        textTransform: "uppercase",
      },

      caption: {
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "1.3em",
        spaceTop: "0px",
        spaceBottom: "- 1px",
        textTransform: "uppercase",
      },
    },
    mobile: {
      h0Heading: createFontStyle("mobile", 6, 9, 200, "-0.06em", "uppercase"),
      h1Heading: createFontStyle("mobile", 6, 9, 200, "-0.06em", "uppercase"),
      h1: createFontStyle("mobile", 6, 9, 200, "-0.06em"),
      h2Heading: createFontStyle("mobile", 7, 9, 200, "-0.06em", "uppercase", 0.8),
      h2HeadingBold: createFontStyle("mobile", 7, 9, 700, "-0.06em", "uppercase"),
      h2: createFontStyle("mobile", 7, 9, 200, "-0.06em"),

      h3Heading: createFontStyle("mobile", 8, 9, 700, "-0.025em", "uppercase"),

      h3: {
        fontWeight: 700,
        fontSize: themeFontSizePx(themeSpace("mobile", 8)),
        lineHeight: "1.3em",
        spaceTop: "- 0.1em",
        spaceBottom: "- 0.2em",
        letterSpacing: "-0.025em",
        valign: themeFontValign(themeSpace("mobile", 8)),
      },

      h4Heading: {
        textTransform: "uppercase",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "1.3em",
        spaceTop: `-${themeFontDistanceTopPx(
          themeSpace("mobile", 9),
          themeSpace("mobile", 9)
        )}`,
        spaceBottom: `- ${themeFontDistanceBottomPx(
          themeSpace("mobile", 9),
          themeSpace("mobile", 9)
        )}`,
        valign: themeFontValign(themeSpace("mobile", 9)),
      },

      h4: {
        fontWeight: 700,
        fontSize: "14px", //themeFontSizePx(themeSpace("mobile", 9)),
        lineHeight: "1.3em",
        spaceTop: "- 0.1em",
        spaceBottom: "- 0.2em",
        valign: themeFontValign(themeSpace("mobile", 9)),
      },

      body: {
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "23px",
        spaceTop: "-1px",
        spaceBottom: "- 2px",
      },

      captionTitle: {
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "1.3em",
        spaceTop: "0px",
        spaceBottom: "- 1px",
        textTransform: "uppercase",
      },

      caption: {
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "1.3em",
        spaceTop: "0px",
        spaceBottom: "- 1px",
        textTransform: "uppercase",
      },
    },
    tablet: {
      h0Heading: createFontStyle("tablet", 5, 9, 200, "-0.06em", "uppercase"),
      h1Heading: createFontStyle("tablet", 6, 9, 200, "-0.06em", "uppercase"),
      h1: createFontStyle("tablet", 6, 9, 200, "-0.06em"),
      h2Heading: createFontStyle("tablet", 7, 9, 200, "-0.06em", "uppercase", 0.8),
      h2HeadingBold: createFontStyle("tablet", 7, 9, 700, "-0.06em", "uppercase"),
      h2: createFontStyle("tablet", 7, 9, 200, "-0.06em"),

      h3Heading: createFontStyle("tablet", 8, 9, 700, "-0.025em", "uppercase"),

      h3: {
        fontWeight: 700,
        fontSize: themeFontSizePx(themeSpace("tablet", 8)),
        lineHeight: "1.3em",
        spaceTop: "- 0.1em",
        spaceBottom: "- 0.2em",
        letterSpacing: "-0.025em",
        valign: themeFontValign(themeSpace("tablet", 8)),
      },

      h4Heading: createFontStyle("tablet", 9, 9, 700, null, "uppercase"),

      h4: {
        fontWeight: 700,
        fontSize: themeFontSizePx(themeSpace("tablet", 9)),
        lineHeight: "1.3em",
        spaceTop: "- 0.1em",
        spaceBottom: "- 0.2em",
        valign: themeFontValign(themeSpace("tablet", 9)),
      },

      body: {
        fontWeight: 400,
        fontSize: "20px",
        lineHeight: "26px",
        spaceTop: "-2px",
        spaceBottom: "- 4px",
        valign: undefined,
      },

      captionTitle: {
        fontWeight: 700,
        fontSize: "15px",
        lineHeight: "18px",
        spaceTop: "-1px",
        spaceBottom: "- 2px",
        valign: undefined,
        textTransform: "uppercase",
      },

      caption: {
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "18px",
        spaceTop: "-1px",
        spaceBottom: "- 2px",
        valign: undefined,
        textTransform: "uppercase",
      },
    },
    desktop: {
      h0Heading: createFontStyle("desktop", 5, 9, 200, "-0.06em", "uppercase"),
      h1Heading: createFontStyle("desktop", 6, 9, 200, "-0.06em", "uppercase"),
      h1: createFontStyle("desktop", 6, 9, 200, "-0.06em"),
      h2Heading: createFontStyle("desktop", 7, 9, 200, "-0.06em", "uppercase", 0.8),
      h2HeadingBold: createFontStyle("desktop", 7, 9, 700, "-0.06em", "uppercase"),
      h2: createFontStyle("desktop", 7, 9, 200, "-0.06em"),

      h3Heading: createFontStyle("desktop", 8, 9, 700, "-0.025em", "uppercase"),

      h3: {
        fontWeight: 700,
        fontSize: themeFontSizePx(themeSpace("desktop", 8)),
        lineHeight: "1.3em",
        spaceTop: "- 0.1em",
        spaceBottom: "- 0.2em",
        letterSpacing: "-0.025em",
        valign: themeFontValign(themeSpace("desktop", 8)),
      },

      h4Heading: createFontStyle("desktop", 9, 9, 700, null, "uppercase"),

      h4: {
        fontWeight: 700,
        fontSize: themeFontSizePx(themeSpace("desktop", 9)),
        lineHeight: "1.3em",
        spaceTop: "- 0.1em",
        spaceBottom: "- 0.2em",
        valign: themeFontValign(themeSpace("desktop", 9)),
      },

      body: {
        fontWeight: 400,
        fontSize: "20px",
        lineHeight: "26px",
        spaceTop: "-3px",
        spaceBottom: "- 5px",
        valign: undefined,
      },

      captionTitle: {
        fontWeight: 700,
        fontSize: "15px",
        lineHeight: "18px",
        spaceTop: "-1px",
        spaceBottom: "- 2px",
        valign: undefined,
        textTransform: "uppercase",
      },

      caption: {
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "18px",
        spaceTop: "-1px",
        spaceBottom: "- 2px",
        valign: undefined,
        textTransform: "uppercase",
      },
    },
    screen: {
      h0Heading: createFontStyle("screen", 5, 9, 200, "-0.06em", "uppercase"),
      h1Heading: createFontStyle("screen", 6, 9, 200, "-0.06em", "uppercase"),
      h1: createFontStyle("screen", 6, 9, 200, "-0.06em"),
      h2Heading: createFontStyle("screen", 7, 9, 200, "-0.06em", "uppercase"),
      h2HeadingBold: createFontStyle("screen", 7, 9, 700, "-0.06em", "uppercase"),
      h2: createFontStyle("screen", 7, 9, 200, "-0.06em"),

      h3Heading: createFontStyle("screen", 8, 9, 700, "-0.025em", "uppercase"),

      h3: {
        fontWeight: 700,
        fontSize: themeFontSizePx(themeSpace("screen", 8)),
        lineHeight: "1.3em",
        spaceTop: "- 0.1em",
        spaceBottom: "- 0.2em",
        letterSpacing: "-0.025em",
        valign: themeFontValign(themeSpace("screen", 8)),
      },

      h4Heading: createFontStyle("screen", 9, 9, 700, null, "uppercase"),

      h4: {
        fontWeight: 700,
        fontSize: themeFontSizePx(themeSpace("screen", 9)),
        lineHeight: "1.3em",
        spaceTop: "- 0.1em",
        spaceBottom: "- 0.2em",
        valign: themeFontValign(themeSpace("screen", 9)),
      },

      body: {
        fontWeight: 400,
        fontSize: "22px",
        lineHeight: "27px",
        spaceTop: "-3px",
        spaceBottom: "- 5px",
        valign: undefined,
      },

      captionTitle: {
        fontWeight: 700,
        fontSize: "17px",
        lineHeight: "21px",
        spaceTop: "-1px",
        spaceBottom: "- 2px",
        valign: undefined,
        textTransform: "uppercase",
      },

      caption: {
        fontWeight: 400,
        fontSize: "17px",
        lineHeight: "21px",
        spaceTop: "-1px",
        spaceBottom: "- 2px",
        valign: undefined,
        textTransform: "uppercase",
      },
    },
  },

  textStyle: function (breakpoint: string, style: string, weight: number) {
    let b = breakpoint.replace("Landscape", "");
    return `
      font-weight: ${
        weight ?? (this as any)?.typography?.[b]?.[style]?.fontWeight ?? 400
      };
      font-size: ${(this as any)?.typography?.[b]?.[style]?.fontSize ?? "14px"};
      line-height: ${
        (this as any)?.typography?.[b]?.[style]?.lineHeight ?? "18px"
      };
      ${
        (this as any)?.typography?.[b]?.[style]?.letterSpacing
          ? `letter-spacing: ${
              (this as any)?.typography?.[b]?.[style]?.letterSpacing
            };`
          : ""
      }
      ${
        (this as any)?.typography?.[b]?.[style]?.textTransform
          ? `text-transform: ${
              (this as any)?.typography?.[b]?.[style]?.textTransform
            };`
          : ""
      }
    `;
  },
};
export type SCIkonTheme = typeof theme;

if (typeof window !== "undefined" && process.env.NODE_ENV === "development")
  console.log(theme);

export default theme;
